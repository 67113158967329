<template>
    <section>
        <top-alternative-section
            :image="image"
            :title="$t('saldos.top.title')"
            :content="$t('saldos.top.content')"
        ></top-alternative-section>

        <description-section
          :title="$t('saldos.description.title')"
          :content="$t('saldos.description.content')"
        ></description-section>
        
        <features-section
          :title="$t('saldos.featuresSection.title')"
          :description="$t('saldos.featuresSection.description')"
          :items="$t('saldos.featuresSection.items')"
        >
        </features-section>

        <offers-section 
          :items="$t('saldos.offersSection.items')"
        ></offers-section>

        <contact-section></contact-section>
    </section>
 </template>
  
  <script>
  import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
  import DescriptionSection from "../sections/DescriptionSection.vue";
  import OffersSection from "../sections/OffersSection.vue";
  import FeaturesSection from "../sections/FeaturesSection.vue";
  import ContactSection from "../sections/ContactSection.vue";
  import LightImage from "../../assets/saldos.png"
  
  export default {
    components: {
      TopAlternativeSection,
      DescriptionSection,
      OffersSection,
      FeaturesSection,
      ContactSection
    },
    data() {
      return {
        image: LightImage
      };
    }
  };
  </script>
  